import { NextPage } from 'next';

import { ErrorPage } from '../components';

const Error404Page: NextPage = () => {
  return <ErrorPage />;
};

// eslint-disable-next-line import/no-default-export
export default Error404Page;
